import axios from "axios"
import errorHandling from "./errorHandling"
import { apiMetrics } from "../../config"
import CryptoJS from 'crypto-js';

export const fetchToken = (url) => async (dispath) => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(url, '32132132132321323213221').toString();

    const tokenApi = await axios.post(`${apiMetrics}/auth`, {
      url: ciphertext
    })
    dispath({ type: 'FETCH_TOKEN', payload: tokenApi.data.token })

  } catch (error) {
    return errorHandling(error);
  }
}

export const getProduct = (token) => async (dispath) => {
  try {
    const products = await axios.get(`${apiMetrics}/product`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispath({ type: 'FETCH_PRODUCTS', payload: products.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getLocations = (token) => async (dispath) => {
  try {
    const locations = await axios.get(`${apiMetrics}/locations`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispath({ type: 'FETCH_LOCATIONS', payload: locations.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getInventoryLevel = (token, startDate, endDate, location, option, product, type) => async (dispath) => {
  try {
    const url = option === 'product' ? `${apiMetrics}/inventory-level/all` : `${apiMetrics}/inventory-level/type`;
    const params = {
      start_date: startDate,
      end_date: endDate,
      expected: 1,
      location_id: location,
      type: 'last_of_each_day'
    }
    if (option === 'product') {
      params.variant_id = product
    } else if (option === 'product_id') {
      params.product_id = type
    } else if (option === 'type') {
      params.product_type = type
    }
    const inventoryLevel = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    })
    dispath({ type: 'FETCH_INVENTORY_LEVEL', payload: inventoryLevel.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getInventoryLevelTypes = (token, startDate, endDate) => async (dispath) => {
  try {
    const url = `${apiMetrics}/inventory-level/get-per-type`;
    const params = {
      start_date: startDate,
      end_date: endDate,
    }
    const inventoryLevel = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    })
    dispath({ type: 'FETCH_INVENTORY_LEVEL_TYPES', payload: inventoryLevel.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getCurveABC = (token, end, start) => async (dispath) => {
  try {
    const url = `${apiMetrics}/order-bigquery-curve?start_date=${start}&end_date=${end}`;
    const curve = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    dispath({ type: 'FETCH_CURVE_ABC', payload: curve.data.rows })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getProductsInfo = (token, start, end, aggregate) => async (dispath) => {
  try {
    console.log('ou')
    const url = `${apiMetrics}/tax-conversion?start_date=${start}&end_date=${end}`;
    const curve = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    console.log('aaaaa', curve)
    dispath({ type: 'FETCH_PRODUCTS_INFO', payload: curve.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getPreviousPeriodProductsInfo = (token, start, end, aggregate) => async (dispath) => {
  try {
    const url = `${apiMetrics}/previous-pixel-data?start_date=${start}&end_date=${end}&aggregate=${aggregate}`;
    const curve = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    dispath({ type: 'FETCH_PREVIOUS_PERIOD_PRODUCTS_INFO', payload: curve.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getOrdersInfo = (token, start, end, aggregate) => async (dispath) => {
  try {
    const url = `${apiMetrics}/orders-info?start_date=${start}&end_date=${end}&aggregate=${aggregate}`;
    const curve = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    dispath({ type: 'FETCH_ORDERS_INFO', payload: curve.data })
  } catch (error) {
    return errorHandling(error);
  }
}

export const getOrders = (token) => async (dispath) => {
  try {

    const url = `${apiMetrics}/order-bigquery-orders-by-day`;
    const orders = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
    dispath({ type: 'FETCH_ORDERS', payload: orders.data })
  } catch (error) {
    return errorHandling(error);
  }
}