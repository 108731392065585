import { Box, Button, Paper, Typography, Grid } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import * as insightsActions from '../../store/actions/insights';
import * as customerActions from '../../store/actions/customers';
import { apiMetrics, keyCrypto } from "../../config"
import BarsOrders from '../../components/Insights/BarsOrders';
import { minCurrency } from '../../utils/String';
import CurveABCTable from '../../components/Insights/CurveABCTable';
import { i18n } from '../../translate/i18n';
import { Spinner } from 'reactstrap';
import { isMobile } from "react-device-detect";
import salesBlurry1 from '../../assets/sales_blurry_1.png';
import salesBlurry2 from '../../assets/sales_blurry_2.png';
import LockIcon from '@mui/icons-material/Lock';
import { debounce } from 'lodash';

const styles = {
  paper: {
    width: isMobile ? "100%" : "50%",
    borderRadius: "1.5rem",
    padding: "1rem",
    backgroundColor: "#F3F3F3",
  }
}

const ReqInstall = ({ img }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right bottom',
        backgroundSize: 'cover',
        height: '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ padding: '1rem', width: "50%", minWidth: "300px" }}>
        <Box fullWidth style={{ textAlign: 'center' }}>
          <Typography variant="h6" style={{ marginTop: '5px', display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
            <LockIcon />
            {i18n.t('dashboard.unLockData')}
          </Typography>
          <Typography variant="body2" style={{ marginTop: '5px' }}>
            {i18n.t('dashboard.textNoInstall')}
          </Typography>
          <br />
          <a href='https://apps.shopify.com/metricas-fullcomm?locale=pt-BR' target='_blank'>
            <Button className='btn-action' variant='contained'>
              {i18n.t('dashboard.installInShopify')}
            </Button>
          </a>
        </Box>
      </Paper>
    </Box>
  )
}

const Sales = (props) => {
  const [noInstall, setNoInstall] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [replace, setReplace] = useState(false)
  const today = new Date();

  const date = new Date();
  date.setDate(date.getDate() - 30);

  const debouncedSearch = useCallback(
    debounce(async (platformDomain) => {
      props.fetchToken(platformDomain)
      try {
        const config_request = {
          method: "get",
          url: `${apiMetrics}/session/offline_${platformDomain}`,
          headers: {
            'fullcomm-token': keyCrypto
          }
        };
        const result = (await axios(config_request)).data;
        if (!result?.id) {
          setNoInstall(true);
          setLoadingOrders(false);
          setLoadingProducts(false);
        } else {
          setNoInstall(false);
        }
      } catch (error) {
        console.log(error);
        setNoInstall(true);
        setLoadingOrders(false);
        setLoadingProducts(false);
        return undefined;
      }
    }, 500),
    []
  );
  const debouncedGetCurvesAndOrders = useCallback(
    debounce(async (token) => {
      props.getOrders(token)
      props.getCurveABC(token, today.toISOString(), date.toISOString());
    }, 500),
    []
  );

  useEffect(async () => {
    props.getViewCustomer(props.shop);
    setLoadingOrders(true);
    setLoadingProducts(true);
  }, [props.shop])

  useEffect(() => {
    if (props.token) {
      debouncedGetCurvesAndOrders(props.token)
    }
  }, [props.token])

  useEffect(() => {
    if (Array.isArray(props?.orders?.rows)) {
      setLoadingOrders(false)
    }
  }, [props.orders])

  useEffect(() => {
    if (Array.isArray(props?.curveABC)) {
      let aux = props?.curveABC.filter((elem) => elem.curva === 'A');
      setFilterData(aux);
      setLoadingProducts(false);
    }
  }, [props.curveABC])

  useEffect(() => {
    if (props.customer) {
      if (props.customer.platformDomain === "experiencia-checkstore.myshopify.com") {
        setReplace(true);
        props.customer.platformDomain = "care-natural-beauty-cosmeticos.myshopify.com";
      }
      debouncedSearch(props.customer.platformDomain);
    }
  }, [props.customer])

  return (
    <Box>
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.sales')}</Typography>
      <Box display="flex" gap="1.5rem" flexDirection={isMobile ? "column" : "row"}>
        <Paper sx={styles.paper}>
          {
            !noInstall && (
              <Box display="flex" gap="0.5rem" alignItems={'center'}>
                <Typography fontSize={"23px"} fontWeight={"bold"}>{i18n.t('dashboard.invoicing')}</Typography>
                <Typography fontSize={"10px"}>({i18n.t('dashboard.last30days')})</Typography>
              </Box>
            )
          }
          <Box>
            {(loadingOrders) ?
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', minHeight: "300px" }}>
                <Spinner loading />
              </div>
              :
              noInstall ?
                <ReqInstall img={salesBlurry1} />
                :
                <Grid container>
                  <Grid item xs={9}>
                    <BarsOrders orders={props?.orders?.rows} height={300} />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: 'center' }}>
                    <Typography variant={isMobile ? "caption" : "body2"}>{i18n.t('dashboard.invoicingTotal')}</Typography>
                    <Typography fontWeight="bold" fontSize={isMobile ? "1rem" : "1.5rem"} mb="1rem">{minCurrency(props?.orders?.summary?.totalRevenue)}</Typography>

                    <Typography variant={isMobile ? "caption" : "body2"}>{i18n.t('dashboard.orders')}</Typography>
                    <Typography fontWeight="bold" fontSize={isMobile ? "1rem" : "1.5rem"} mb="1rem">{(props?.orders?.summary?.totalOrders) || 0}</Typography>

                    <Typography variant={isMobile ? "caption" : "body2"}>{i18n.t('dashboard.ticketMedio')}</Typography>
                    <Typography fontWeight="bold" fontSize={isMobile ? "1rem" : "1.5rem"} mb="1rem">{minCurrency(props?.orders?.summary?.overallAverageTicket) || 0}</Typography>
                  </Grid>
                </Grid>
            }
          </Box>
        </Paper>
        <Paper sx={styles.paper}>
          {
            !noInstall && (
              <Box display="flex" gap="0.5rem" alignItems={'center'}>
                <Typography fontSize={"23px"} fontWeight={"bold"}>{i18n.t('dashboard.products')}</Typography>
                <Typography fontSize={"10px"}>({i18n.t('dashboard.last30days')})</Typography>
              </Box>
            )
          }
          {(loadingProducts) ?
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', minHeight: "300px" }}>
              <Spinner loading />
            </div>
            :
            noInstall ?
              <ReqInstall img={salesBlurry2} />
              :
              <>
                <CurveABCTable tab={-1} showDetails={() => { }} data={filterData} replace={replace} />
              </>
          }
        </Paper>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  orders: state.insight.orders,
  curveABC: state.insight.curveABC,
})

export default connect(mapStateToProps, { ...insightsActions, ...customerActions })(Sales);