import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import * as insightsActions from '../../../store/actions/insights';
import * as customerActions from '../../../store/actions/customers';
import { connect } from 'react-redux';
import { Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { i18n } from '../../../translate/i18n';
import axios from 'axios';
import { format } from 'date-fns';
import { Typography, Box, Tab, Stack, Dialog, Card, CardContent, Tooltip, IconButton, Container, Grid, Chip, Button } from '@mui/material';
import CurveABCTable from '../../../components/Insights/CurveABCTable';
import CurveRiskStockTable from '../../../components/Insights/CurveRiskStockTable';
import { LoadingButton } from '@mui/lab';
import LevelTypes from '../../../components/Insights/LevelTypes';
import MultipleYAxis from '../../../components/Insights/MultipleYAxis';
import InfoIcon from '@mui/icons-material/Info';
import { AccessTime, TrendingUp, Assessment, CheckCircle, BarChart } from '@mui/icons-material';
import CsvDownloadButton from 'react-json-to-csv';

import { apiMetrics, keyCrypto } from "../../../config"
import { FCTabs, FCTextField } from 'components-fullcomm';
const CurveABC = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingMin, setLoadingMin] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 'yyyy-MM-dd'));
  const [firstTab, setFirstTab] = useState(0);
  const [tab, setTab] = useState(1);
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [noInstall, setNoInstall] = useState(false);
  const [filterData, setFilterData] = useState();
  const [query, setQuery] = useState('');
  const [replace, setReplace] = useState(false);
  useEffect(async () => {
    props.getViewCustomer(props.shop);
  }, [props.shop])

  useEffect(async () => {
    if (props.token) {
      await search();
      setLoadingMaster(false)
    }
  }, [props.token])


  useEffect(async () => {
    if (props.customer) {
      if (props.customer.platformDomain === "experiencia-checkstore.myshopify.com") {
        setReplace(true);
        props.customer.platformDomain = "care-natural-beauty-cosmeticos.myshopify.com";
      }
      props.fetchToken(props.customer.platformDomain)

      try {
        const config_request = {
          method: "get",
          url: `${apiMetrics}/session/offline_${props.customer.platformDomain}`,
          headers: {
            'fullcomm-token': keyCrypto
          }
        };
        const result = (await axios(config_request)).data;
        console.log(result)
        if (!result?.id) {
          setNoInstall(true)
          setLoadingMaster(false)
        } else {
          setNoInstall(false)
        }
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }
  }, [props.customer])

  useEffect(() => {
    if (query.length > 0) {
      const aux = data.filter((item) => item.title?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || item.title_variant?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || item.sku?.toLowerCase().includes(query.toLowerCase()) || item.product_type?.toLowerCase().includes(query.toLowerCase()));
      setFilterData(aux);
    } else {
      setFilterData(data);
    }
  }, [query, data])

  const search = async () => {
    if (props.token) {
      setLoadingMin(true)
      // props.getInventoryLevelTypes(props.token, startDate, endDate);
      props.getCurveABC(props.token, endDate, startDate);
    }
  };

  useEffect(() => {
    if (props.curveABC) {
      setLoading(false)
      setLoadingMin(false)
      setData(props.curveABC)
    }
  }, [props.curveABC, tab, firstTab])

  if (loadingMaster) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        <Spinner loading={loadingMaster} />
      </div>
    )
  }

  if (noInstall) {
    const appIcons = [
      <AccessTime />,
      <TrendingUp />,
      <Assessment />,
      <CheckCircle />,
      <BarChart />,
    ];

    const appBenefits = [
      i18n.t('curveABC.noInstall.benefit1'),
      i18n.t('curveABC.noInstall.benefit2'),
      i18n.t('curveABC.noInstall.benefit3'),
      i18n.t('curveABC.noInstall.benefit4'),
      i18n.t('curveABC.noInstall.benefit5')
    ];
    return (
      <Container maxWidth="md" style={{ marginTop: '20px' }}>
        <Typography variant="h4" gutterBottom>

          {i18n.t('curveABC.noInstall.title')} <Chip label={i18n.t('curveABC.noInstall.new')} color="success" />
        </Typography>
        <Grid container spacing={2} mt={2}>
          {appIcons.map((icon, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  <div style={{ textAlign: 'center' }}>{icon}</div>
                  <br />
                  <Typography variant="body2">
                    {appBenefits[index]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body2" style={{ marginTop: '20px' }}>
          {i18n.t('curveABC.noInstall.text1')}
          <br />
          {i18n.t('curveABC.noInstall.text2')}
          <br />
          {i18n.t('curveABC.noInstall.text3')}
        </Typography>
        <br />
        <Box fullWidth style={{ textAlign: 'center' }}>
          <a href='https://apps.shopify.com/metricas-fullcomm?locale=pt-BR' target='_blank'>
            <Button style={{ backgroundColor: 'green' }} variant='contained'>
              {i18n.t('curveABC.noInstall.unblock')}</Button>
          </a>
        </Box>
      </Container>
    );
  }
  return (
    <div style={{ padding: isMobile ? '16px 16px 66px 16px' : '16px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h4" gutterBottom>
          {i18n.t('insight.title')}
        </Typography>
      </div>
      <Stack direction="row" justifyContent="flex-start" alignItems="end" spacing={2} mt={2} >
        <FCTextField value={startDate} onChange={e => setStartDate(e.target.value)} id="date" label={i18n.t('insight.startDate')} type="date" />
        <FCTextField value={endDate} onChange={e => setEndDate(e.target.value)} id="date" label={i18n.t('insight.endDate')} type="date" />
        <LoadingButton onClick={() => search()} loading={loadingMin} variant="contained" className='btn-white medium mb-1' >{i18n.t('insight.search')}</LoadingButton>
      </Stack>
      <br />
      {data &&
        <>
          <>
            <Stack direction="row" spacing={2} mt={2} mb={2} >
              <FCTextField value={query} onChange={e => setQuery(e.target.value)} label={i18n.t('insight.search')} />
            </Stack>
          </>
          {loadingMin ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Spinner loading={loading} />
          </div> :
            <>
              <CurveABCTable tab={tab} data={filterData} replace={replace} />
            </>
          }
        </>
      }
    </div>
  );

};


const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  curveABC: state.insight.curveABC,
  inventoryLevel: state.insight.inventoryLevel,
  inventoryLevelTypes: state.insight.inventoryLevelTypes,
})

export default connect(mapStateToProps, { ...insightsActions, ...customerActions })(CurveABC);

