import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as insightsActions from '../../../store/actions/insights';
import * as scaleActions from '../../../store/actions/scale';
import * as customerActions from '../../../store/actions/customers';
import { apiMetrics, keyCrypto } from "../../../config";
import { i18n } from '../../../translate/i18n';
import { isMobile } from "react-device-detect";
import { debounce } from 'lodash';
import ProductsInfo from '../../../components/Insights/ProductsInfo';
import { Box, Button, CircularProgress, Stack, Tab, Typography } from '@mui/material';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import LockIcon from '@mui/icons-material/Lock';
import { FCTabs, FCTextField } from 'components-fullcomm';

const CustomTabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const ProductsOrdersInfo = (props) => {
  const [value, setValue] = useState(0);
  const [noInstall, setNoInstall] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(Date.now() - 86400000), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [loading, setLoading] = useState(true);
  const [finalData, setFinalData] = useState([]);

  const fetchInsights = useCallback(debounce(async (platformDomain) => {
    setLoading(true);
    props.fetchTokenInsights(platformDomain);
    try {
      const { data: result } = await axios.get(`${apiMetrics}/session/offline_${platformDomain}`, {
        headers: { 'fullcomm-token': keyCrypto }
      });
      setNoInstall(!result?.id);
    } catch {
      setNoInstall(true);
    } finally {
      setLoading(false);
    }
  }, 500), []);

  const fetchProductsInfo = useCallback(debounce(async (token, start, end) => {
    props.getProductsInfo(token, start, end);
  }, 1500), []);

  useEffect(() => {
    const filterProducts = () => {
      const auxConversionRates = props.productsInfo?.conversionRates || [];
      const filters = [
        { condition: value === 0, filter: product => product.session_percentage <= 8 && product.conversion_rate > 10 && product.add_to_cart_rate > 20 },
        { condition: value === 1, filter: product => product.session_percentage >= 10 && product.conversion_rate < 5 && product.add_to_cart_rate >= 5 && product.add_to_cart_rate <= 15 },
        { condition: value === 2, filter: product => product.session_percentage >= 8 && product.conversion_rate < 8 },
        { condition: value === 3, filter: product => product.conversion_rate <= 20 && product.add_to_cart_rate >= 10 },
      ];
      const filteredData = auxConversionRates.filter(product => filters.some(f => f.condition && f.filter(product)));
      setFinalData(filteredData);
    };
    filterProducts();
  }, [props.productsInfo, value]);

  useEffect(() => {
    setLoading(true);
    props.getViewCustomer(props.shop);
  }, [props.shop]);

  useEffect(() => {
    if (props.token) {
      fetchProductsInfo(props.token, startDate, endDate);
    }
  }, [props.token, startDate, endDate]);

  useEffect(() => {
    if (props.customer) {
      const platformDomain = props.customer.platformDomain === "experiencia-checkstore.myshopify.com" ? "care-natural-beauty-cosmeticos.myshopify.com" : props.customer.platformDomain;
      fetchInsights(platformDomain);
    }
  }, [props.customer]);

  useEffect(() => {
    setLoading(false);
  }, [props.productsInfo]);

  return (
    <Box className="dashboard">
      <Typography variant="h4" mb="1.5rem">{i18n.t('dashboard.sales')}</Typography>
      <Stack direction="row" justifyContent="flex-start" alignItems="end" spacing={2} mt={2}>
        <FCTextField
          value={startDate}
          onChange={e => setStartDate(e.target.value)}
          id="date" label={i18n.t('insight.startDate')}
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <FCTextField
          value={endDate}
          onChange={e => setEndDate(e.target.value)}
          id="date"
          label={i18n.t('insight.endDate')}
          type="date"
          InputLabelProps={{ shrink: true }}
        />
        <LoadingButton onClick={() => {
          setLoading(true);
          fetchProductsInfo(props.token, startDate, endDate);
        }}
          loading={loading}
          className='btn-white medium mb-1'
          variant="contained"
        >
          {i18n.t('insight.search')}
        </LoadingButton>
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <FCTabs
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          aria-label="basic tabs example"
          variant={isMobile ? 'scrollable' : 'standard'}
          scrollButtons={isMobile ? "on" : "off"}
        >
          <Tab label="Potencial elevado" />
          <Tab label="Como melhorar" />
          <Tab label="Não está vendendo" />
          <Tab label="Frequentemente abandonado" />
        </FCTabs>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : noInstall ? (
        <Box fullWidth style={{ marginTop: '2rem', textAlign: 'center' }}>
          <Typography variant="h6" style={{ marginTop: '5px', display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
            <LockIcon />
            {i18n.t('dashboard.unLockData')}
          </Typography>
          <Typography variant="body2" style={{ marginTop: '5px' }}>
            {i18n.t('dashboard.textNoInstall')}
          </Typography>
          <br />
          <a href='https://apps.shopify.com/metricas-fullcomm?locale=pt-BR' target='_blank'>
            <Button className='btn-action' variant='contained'>
              {i18n.t('dashboard.installInShopify')}
            </Button>
          </a>
        </Box>
      ) : (
        <>
          {[...Array(4)].map((_, index) => (
            <CustomTabPanel key={index} value={value} index={index}>
              <ProductsInfo productsInfo={finalData} tab={index} />
            </CustomTabPanel>
          ))}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  shop: state.auth.shop,
  customer: state.customer.customerDetails,
  token: state.insight.tokenApi,
  productsInfo: state.insight.productsInfo,
  ordersInfo: state.insight.ordersInfo,
  shippingOrders: state.scale.smartShippingOrders,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTokenInsights: (platformDomain) => dispatch(insightsActions.fetchToken(platformDomain)),
  fetchTokenShipping: () => dispatch(scaleActions.fetchToken()),
  getViewCustomer: (shop) => dispatch(customerActions.getViewCustomer(shop)),
  getProductsInfo: (token, start, end) => dispatch(insightsActions.getProductsInfo(token, start, end)),
  getOrdersInfo: (token, start, end) => dispatch(insightsActions.getOrdersInfo(token, start, end)),
  getOrderShippingValue: (start, end) => dispatch(scaleActions.getOrderShippingValue(start, end)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsOrdersInfo);