import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Typography, Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import GradingIcon from '@mui/icons-material/Grading';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import RuleIcon from '@mui/icons-material/Rule';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { i18n } from '../../translate/i18n';
import TopicIcon from '@mui/icons-material/Topic';
import { debounce } from 'lodash';
import axios from 'axios';
import { apiMetrics, keyCrypto } from '../../config';

const Sprint = ({ user, MenuLink, checklistNotifications, activitiesNotifications, shop, getViewCustomer, customer }) => {
    const [isOpen, setIsOpen] = useState(localStorage.getItem('sprintSidebar') === 'false' ? false : true);
    const [metricsApp, setMetricsApp] = useState(false);

    const debouncedSearch = useCallback(
        debounce(async (platformDomain) => {
          try {
            const config_request = {
              method: "get",
              url: `${apiMetrics}/session/offline_${platformDomain}`,
              headers: {
                'fullcomm-token': keyCrypto
              }
            };
            const result = (await axios(config_request)).data;
            if (result?.id) {
              setMetricsApp(true);
            } else {
              setMetricsApp(false);
            }
          } catch (error) {
            setMetricsApp(false);
          }
        }, 50),
        []
      );

    const handleToggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        localStorage.setItem('sprintSidebar', isOpen);
    }, [isOpen]);

    useEffect(() => {
        getViewCustomer(shop);
    }, [shop]);

    useEffect(() => {
        debouncedSearch(customer?.platformDomain);
    }, [customer]);


    const menuItems = [
        { to: "/estrategia", Icon: GradingIcon, label: i18n.t('components.sideBar.sprint.strategy') },
        { to: "/action-list", Icon: ReceiptLongIcon, label: i18n.t('components.sideBar.sprint.actionsList') },
        { to: "/action-plan", Icon: PendingActionsIcon, label: i18n.t('components.sideBar.sprint.actionPlan') },
        { to: "/kanban", Icon: ViewKanbanIcon, label: i18n.t('components.sideBar.sprint.kanban'), badge: activitiesNotifications },
        { to: "/action/checklists", Icon: RuleIcon, label: i18n.t('components.sideBar.sprint.checklists'), badge: checklistNotifications }
    ];


    return (
        <div>
            <Typography onClick={handleToggleCollapse} variant="h6" sx={{ cursor: 'pointer', color: 'white', fontSize: '14px', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px' }}>
                {i18n.t('components.sideBar.sprint.performance')}
            </Typography>
            <Collapse in={isOpen}>
                <List component="nav">
                    {/* {user?.permissionCustomer?.find((e) => e.customer === localStorage.getItem('shop'))?.actions?.includes('create_action') ||
                        user?.permissionAdmin?.includes('create_action') ? (
                        <MenuLink to="/new-action" Icon={LibraryAddIcon}>{i18n.t('components.sideBar.sprint.newAction')}</MenuLink>
                    ) : null} */}
                    {/* {
                        metricsApp && (
                            <MenuLink to="/results" Icon={BackupTableIcon}>{i18n.t('components.sideBar.sprint.results')}</MenuLink>
                        )
                    } */}
                    {menuItems.map(({ to, Icon, label, badge }) => (
                        (user?.permissionCustomer?.find(e => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_action') ||
                            user?.permissionAdmin?.includes('view_action') ||
                            user?.role === "agency") && (
                            <MenuLink key={label} to={to} badge={badge} Icon={Icon}>{label}</MenuLink>
                        )
                    ))}
                    {user?.permissionAdmin?.includes('view_document') && (
                        <MenuLink to="/documents/sprint" Icon={TopicIcon}>
                            {i18n.t('documents.index.title')}
                        </MenuLink>
                    )}
                    {user?.permissionCustomer?.find((e) => e.customer === localStorage.getItem('shop'))?.actions?.includes('view_document') && (
                        <MenuLink to="/documents/sprint" Icon={TopicIcon}>
                            {i18n.t('documents.index.title')}
                        </MenuLink>
                    )}
                </List>
            </Collapse>
        </div>
    );
};
export default Sprint;
