import React, { useState } from 'react';
import { i18n } from '../../translate/i18n';
import { format } from 'date-fns';
import { minCurrency } from '../../utils/String';

import { Table, TableBody, TableCell, TableRow, Paper, Tooltip, TableSortLabel, Chip, Avatar } from '@mui/material';
import { FCTableContainer, FCTableHeader } from 'components-fullcomm';

function CurveABCTable({ tab, data, showDetails, replace }) {
    const [orderBy, setOrderBy] = useState(tab === -1 ? 'date_finish' : null);
    const [order, setOrder] = useState('asc');
    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortedData = [...data || []].sort((a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];

        if (typeof valA === 'string' && typeof valB === 'string') {
            const comparison = valA.localeCompare(valB, undefined, { sensitivity: 'accent' });
            return order === 'asc' ? comparison : -comparison;
        }

        if (valA < valB) {
            return order === 'asc' ? -1 : 1;
        }
        if (valA > valB) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    let option = '';
    if (tab === 0) {
        option = 'product'
    } else if (tab === 1) {
        option = 'product_id'
    } else if (tab === 2) {
        option = 'type'
    }

    return (<FCTableContainer style={tab === -1 ? { height: '300px' } : {}} border={null} component={tab !== -1 && Paper}>
        <Table>
            {tab !== -1 &&
                <FCTableHeader>
                    <TableRow>
                        {(tab === 0 || tab === 1 || tab === -1) &&
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'title'}
                                    direction={order}
                                    onClick={() => handleSort('title')}
                                >
                                    {i18n.t('curveABC.tableHeaders.title')}
                                </TableSortLabel>
                            </TableCell>
                        }
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'sku'}
                                direction={order}
                                onClick={() => handleSort('sku')}
                            >
                                {i18n.t('curveABC.tableHeaders.sku')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'total_quantity'}
                                direction={order}
                                onClick={() => handleSort('total_quantity')}
                            >
                                {i18n.t('curveABC.tableHeaders.quantity')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'total_price'}
                                direction={order}
                                onClick={() => handleSort('total_price')}
                            >
                                {i18n.t('curveABC.tableHeaders.amount')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'percent_faturamento'}
                                direction={order}
                                onClick={() => handleSort('percent_faturamento')}
                            >
                                {i18n.t('curveABC.tableHeaders.percent')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'stock'}
                                direction={order}
                                onClick={() => handleSort('stock')}
                            >
                                {i18n.t('curveABC.tableHeaders.stock')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'value_stock'}
                                direction={order}
                                onClick={() => handleSort('value_stock')}
                            >
                                {i18n.t('curveABC.tableHeaders.valueStock')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'date_finish'}
                                direction={order}
                                onClick={() => handleSort('date_finish')}
                            >
                                {i18n.t('curveABC.tableHeaders.expectedFinish')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                active={orderBy === 'curva'}
                                direction={order}
                                onClick={() => handleSort('curva')}
                            >
                                {i18n.t('curveABC.tableHeaders.curve')}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>

                </FCTableHeader>
            }
            <TableBody>
                {sortedData?.map(product => {
                    let d = {}
                    if (tab === 0) {
                        d = {
                            product: product.variant_id
                        }
                    } else if (tab === 1 || tab === -1) {
                        d = {
                            type: product.product_id
                        }
                    } else {
                        d = {
                            type: product.product_type
                        }
                    }
                    const max_length = tab === -1 ? 25 : 20;
                    product.title = replace ? product.title?.replace(/care/gi, 'FullComm') : product.title;
                    product.sku = replace ? product.sku?.replace(/care/gi, 'FullComm') : product.sku;
                    return (
                        <TableRow style={{ cursor: 'pointer' }} onClick={() => showDetails({ option, ...d })} key={product.variant_id}>
                            {(tab === 0 || tab === 1 || tab === -1) &&
                                <>
                                    <TableCell sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                                        <Avatar
                                            src={product.url_image}
                                            alt={product.title}
                                        />
                                        {product.title?.length > max_length ? <Tooltip title={product.title}><span>{product.title?.substr(0, max_length) + '...'}</span></Tooltip> : product.title}
                                    </TableCell>

                                </>
                            }
                            {tab !== -1 &&
                                <>
                                    <TableCell>{product.sku}</TableCell>
                                    <TableCell>{product.total_quantity}</TableCell>
                                </>
                            }
                            <TableCell>{minCurrency(product.total_price)}</TableCell>
                            {tab !== -1 &&
                                <>
                                    <TableCell>{(product.percent_faturamento * 100)?.toFixed(2)}%</TableCell>
                                    <TableCell>{product.stock}</TableCell>
                                    <TableCell>{minCurrency(product.cost * product.stock)}</TableCell>
                                </>
                            }
                            <TableCell>
                                <Chip style={{
                                    backgroundColor: product.day_finish <= 30 ? '#e74c3c' : product.day_finish <= 60 ? '#f1c40f' : '#2ecc71',
                                    color: 'white',
                                    fontWeight: 'bold'
                                }} label={format(new Date(product?.date_finish), "dd/MM/yyyy")}
                                /></TableCell>
                            <TableCell>
                                <Chip style={{
                                    backgroundColor: product.curva === 'A' ? '#2ecc71' : product.curva === 'B' ? '#f1c40f' : '#e74c3c',
                                    color: 'white',
                                    fontWeight: 'bold'
                                }} label={product.curva}
                                />
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </FCTableContainer>
    )
}
export default CurveABCTable;
